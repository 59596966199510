import { BehaviorSubject } from 'rxjs';

export const breadcrumbsState = new BehaviorSubject({
  customers: {},
  prospects: {},
  contacts: {},
  interactions: {},
  opportunities: {},
  posts: {},
  alerts: {},
  users: {},
  roles: {}
});

export async function updateBreadcrumb(entity, name, value) {
  const state = breadcrumbsState.getValue();
  state[entity][name] = value;
  breadcrumbsState.next(state);
}