import { BehaviorSubject } from 'rxjs';
import { TranslationsSubject, translationsOptions } from '@/types/translationsSubjectTypes';
import { httpRequest } from '@/functions/httpRequest';

export const translationsState = new BehaviorSubject<TranslationsSubject>({
  data: {},
  locale: localStorage.getItem('locale') || 'en-gb', // Get the value from localStorage. If there is no value, set it to 'en-gb'
  loaded: false,
  loading: false,
});



export async function getTranslations(options: translationsOptions) {
  try {
    const { locale } = options;
    const data = await httpRequest('get', `config/lang/${locale}`, {}, {version: '2', throwErrors: false, });
    let mappedTranslations = {};
    data.forEach(area => {
      mappedTranslations = {
        ...mappedTranslations,
        ...area.data,
      };
    });
    translationsState.next({
      data: mappedTranslations,
      locale,
      loaded: false,
      loading: false,
    });
  } catch (error) {
    console.error(error);
  }
}