import { BehaviorSubject } from 'rxjs';
import { Subject } from '@/types/tenantSubjectTypes';
import { httpRequest } from '@/functions/httpRequest';

export const widgetState = new BehaviorSubject({
  data: <Subject>{},
  error: false,
  pending: false,
});

export const hasWidgetState = new BehaviorSubject(null);

export async function getWidget(id, widgetId) {
  try {
    const widget = await httpRequest('get', `dashboards/${id}/widget/${widgetId}`, {}, { area: 'dashboard' });
    hasWidgetState.next(true);
    widgetState.next({
      data: widget,
      error: false,
      pending: false,
    });
    return widget; // Return the widget data
  } catch(e) {
    hasWidgetState.next(false);
    console.error('Error fetching widget:', e);
    return null;
  }
}
