import { GraphQLClient } from 'graphql-request';
import Cookies from 'js-cookie';
import { StatusCodes } from 'http-status-codes';
import { GQLRequestOptions, Error } from '@/types/requestTypes';
import hasOwnProperty from './hasOwnProperty';
import { redirectLogin } from './redirectLogin';
import { setErrors } from './setErrors';

const { NOT_FOUND, UNAUTHORIZED, } = StatusCodes;

const defaultEndpoint = `${process.env.BASE_URL}/enquiries/v2/query`;

export const client = new GraphQLClient(defaultEndpoint, {
  credentials: 'include',
  mode: 'cors',
});

const defaultOptions = {
  throwErrors: true,
  debug: false,
};

const getHeaders = () => {
  if (process.env.NODE_ENV === 'development') {
    const jwt = Cookies.get('token');
    return {
      authorization: `Bearer ${jwt}`,
    };
  }

  return {};
};

export const gqlRequest = async (query, variables = {}, options: GQLRequestOptions = {}, endpoint = defaultEndpoint) => {
  options = {
    ...defaultOptions,
    ...options
  };
  client.setEndpoint(endpoint);
  const headers = getHeaders();
  try {
    // eslint-disable-next-line
    if (options.debug) console.log('gqlRequest, request: ', {query, variables, endpoint,});
    const response = await client.request(query, variables, headers);
    // eslint-disable-next-line
    if (options.debug) console.log('gqlRequest, response: ', response);
    return response;
  } catch (error) {
    const errObj = {
      status: NOT_FOUND,
      errors: [] as Error[]
    };
    try {
      let errorData = null;
      if (hasOwnProperty(error, 'response') && hasOwnProperty(error.response, 'status')) {
        const { status, errors } = error.response;
        if (status === UNAUTHORIZED) {
          redirectLogin();
          throw ({ status: UNAUTHORIZED });
        }
        errObj.status = status;
        errObj.errors = (errors || []).map(error => ({
          message: error.message,
          path: error.path,
          ...error.extensions
        }));
        errorData = errors;
      }
      if (options.throwErrors) {
        let errors = {};
        if (Array.isArray(errObj.errors) && errObj.errors[0]) {
          errors = errObj;
        } else if (typeof errObj.errors === 'object') {
          errObj.errors = [{
            title: 'Unexpected Error',
            message: errorData.message ?? errorData.error ?? 'Whoops',
            trace_id: errorData.trace_id ?? errorData.code ?? '',
          }];
          errors = errObj;
        } else {
          errors = [{
            title: 'Unexpected Error',
            message: errObj.errors,
          }];
        }
        setErrors(errors);
      }
    } catch (error) {
      if (error.status !== UNAUTHORIZED) {
        setErrors({ errors: [{
          title: 'Unexpected Error',
          message: error,
        }] });
      }
    }
    throw errObj;
  }
};
