import { translationsState } from '@/subjects/translations';

interface translationOptions {
  interpolations?: object
}

const translationsDefault = {
  interpolations: {},
};

// Key must be set if there are interpolations, this is why it is not part of options
export const t = (defaultValue, key = 'UNSET', options: translationOptions = {}) => {
  /* TODO: find a better way to deal with undefined store (happens on page refresh and will mean
  translations will never work on initial load of a page after refresh) */
  const store = translationsState.getValue();
  // if (!store) return defaultValue;
  options = {
    ...translationsDefault,
    ...options,
  };
  if (key === 'UNSET' && typeof defaultValue === 'string') {
    key = defaultValue.split(' ').join('_').toLowerCase().replace(/[^a-zA-Z\d\s_-]/ig, '');
  }

  let translation = store.data[key];

  if (!translation) {
    translation = defaultValue;
  }

  const interpolationKeys = Object.keys(options.interpolations);
  if (interpolationKeys.length > 0) {
    interpolationKeys.forEach(key => {
      translation = translation.replace(new RegExp(`%${key}%`, 'ig'), options.interpolations[key]);
    });
  }
  return translation;
};
