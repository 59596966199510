import { httpRequestRaw } from './httpRequestRaw';
import { StatusCodes } from 'http-status-codes';
import { HttpRequestOptions, Error } from '@/types/requestTypes';
import { setErrors } from './setErrors';
import hasOwnProperty from './hasOwnProperty';
import { redirectLogin } from './redirectLogin';

const { NOT_FOUND, UNAUTHORIZED, } = StatusCodes;

const defaultOptions = {
  version: '2',
  area: 'files',
  throwErrors: true,
  debug: false,
  returnPromise: true,
  config :{
    responseType: 'arraybuffer',
  }
};

export const httpFileRequest = async (endpoint, options: HttpRequestOptions = defaultOptions) => {
  options = {
    ...defaultOptions,
    ...options
  };

  try {
    // eslint-disable-next-line
    if (options.debug) console.log('httpFileRequest, request: ', { endpoint, options });
    const response = await httpRequestRaw('get', endpoint, {}, options);
    // eslint-disable-next-line
    if (options.debug) console.log('httpFileRequest, response: ', response);
    return response;
  } catch (error) {
    const errObj = {
      status: NOT_FOUND,
      errors: [] as Error[]
    };
    
    try {
      let errorData = null;
      if (hasOwnProperty(error, 'response') && hasOwnProperty(error.response, 'status')) {
        const { status, data } = error.response;
        if (status === UNAUTHORIZED) {
          redirectLogin();
          throw ({ status: UNAUTHORIZED });
        }
        const decodedData = JSON.parse(new TextDecoder().decode(data));
        errObj.status = status;
        errObj.errors = decodedData;
        errorData = decodedData;
      }
      if (options.throwErrors) {
        let errors = {};
        if (Array.isArray(errObj.errors) && errObj.errors[0]) {
          errors = errObj;
        } else if (typeof errObj.errors === 'object') {
          errObj.errors = [{
            title: 'Unexpected Error',
            message: errorData.message ?? errorData.error ?? 'Whoops',
            trace_id: errorData.trace_id ?? errorData.code ?? '',
          }];
          errors = errObj;
        } else {
          errors = [{
            title: 'Unexpected File Download Error',
            message: errObj.errors,
          }];
        }
        setErrors(errors);
      }
    } catch (error) {
      if (error.status !== UNAUTHORIZED) {
        setErrors({ errors: [{
          title: 'Unexpected File Download Error',
          message: error,
        }] });
      }
    }  
    throw errObj;
  }
};
