import { BehaviorSubject } from 'rxjs';
import { t } from '@/functions/translate';

export const modalQueueState = new BehaviorSubject({
  data: [],
  no_roles_pbac_error: false,
});

export function updateQueue(items) {
  const state = modalQueueState.getValue();
  if (Array.isArray(items) && items[0].message === 'unable to set pbac roles for user') {
    if (!state.no_roles_pbac_error) {
      items[0].title = t('No permissions');
      items[0].message = t('User has no permissions and cannot access the application. Please contact your administrator for access.');
      items[0].closeFunction = () => {
        window.location.href = process.env.REDIRECT_URL;
      };
      state.data = state.data.concat(items);
    }
    state.no_roles_pbac_error = true;
  } else if (items && typeof items === 'object') {
    state.data = state.data.concat(items);
  }
  modalQueueState.next(state);
}

export function popMessageFromStack(index = 0) {
  const state = modalQueueState.getValue();
  const newData = state.data.slice();
  newData.splice(index, 1);
  modalQueueState.next({
    data: newData,
    no_roles_pbac_error: state.no_roles_pbac_error
  });
}

export function clearQueue() {
  modalQueueState.next({
    data: [],
    no_roles_pbac_error: false,
  });
}