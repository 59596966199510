import { BehaviorSubject } from 'rxjs';

export const notificationDashboardState = new BehaviorSubject({
  openWidgetModal: false,
  widgetToAdd: {},
});

export function updateWidgetToAdd(widgetToAdd = {}, openWidgetModal = false) {
  notificationDashboardState.next({
    openWidgetModal,
    widgetToAdd
  });
}

