import { BehaviorSubject } from 'rxjs';
import { Tenant } from '@/types/tenantSubjectTypes';
import { httpRequest } from '@/functions/httpRequest';

export const tenantState = new BehaviorSubject<Tenant>({});

export async function getTenantDetails() {
  try {
    const data = await httpRequest('get', 'tenants/current', {}, {
      version: '2',
      area: 'iam'
    });
    tenantState.next(data);
  } catch (error) {
    console.error(error);
  }
}