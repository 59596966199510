import axios from 'axios';
import Cookies from 'js-cookie';
import { HttpRequestOptions } from '@/types/requestTypes';
import hasOwnProperty from './hasOwnProperty';

const payloadMethods = ['put', 'post', 'patch'];

export const $axios = axios.create();

$axios.interceptors.request.use(config => {
  if (process.env.NODE_ENV === 'development') {
    const jwt = Cookies.get('token');
    config.headers['Authorization'] = `Bearer ${jwt}`;
  }

  return config;
});

/**
 * A raw version of the http request file, that includes no additional store functionality
 */
export const httpRequestRaw = async (method = 'get', endpoint, payload = {}, options: HttpRequestOptions = { version: '2', }) => {
  const config = {
    withCredentials: hasOwnProperty(options, 'withCredentials') ? options.withCredentials : true,
    headers: {},
    params: {},
    ...options.config
  };

  const baseEndpoint = `${process.env.BASE_URL}/${options.area}/v${options.version}`;
  

  if (method === 'delete') {
    config['data'] = payload;
  }

  let url = '';
  if (!endpoint.match(/http[s]?:\/\//g)) {
    url = `${baseEndpoint}/${endpoint}`;
  } else {
    url = endpoint;
  }

  // if we want raw result
  if (options.returnPromise) {
    return $axios[method](
      url,
      config
    );
  }

  const { data, user, } = await $axios[method](
    url,
    payloadMethods.includes(method) ? payload : config,
    config
  );
  return data || user;
};