import { updateQueue } from '@/subjects/modalQueue';

export function setErrors(error, dispatch = true) {
  const errors = error.errors.map(err => ({
    title: err.message,
    message: `Please contact support and quote the trace ID: ${err.trace_id}`,
  }));
  if (dispatch) {
    updateQueue(errors);
  }
  return errors;
}
