/**
 * Helper functions to work with objects, etc
 * @author: diakovlev
 */

/**
 * @function deepClone
 * Deep clone of object
 *
 * @param {Object} value Object to clone
 * @return {Object} copy of the value
 */
export const deepClone = (value) =>
  JSON.parse(JSON.stringify(value));

/**
 * @function isEqualObjects
 * Compare two objects are equal
 *
 * @param {Object} value1 Object1
 * @param {Object} value2 Object2 to compare with Object1
 * @return {boolean} true if objects are same
 */
export const isEqualObjects = (value1, value2) =>
  JSON.stringify(value1) === JSON.stringify(value2);

export const getKeyByValue = (object, value) =>
  Object.keys(object).find(key => object[key] === value);

export const searchObject = (obj, searchText) => {
  for (const prop in obj) {
    const value = obj[prop];
    if (typeof value === 'object') {
      const nestedResult = searchObject(value, searchText);
      if (nestedResult) return true;
    } else if (typeof value === 'string' && value.toLowerCase().includes((searchText || '').toLowerCase())) {
      return true;
    }
    else if (value == searchText) return true;
  }
  return false;
};
