import { BehaviorSubject } from 'rxjs';


export const searchBarState = new BehaviorSubject({
  overlay: false,
  show: false,
  type: undefined,
  isGlobal: true,
});

export const showSearchBar = (isGlobal = true) => {
  const currentState = searchBarState.getValue();
  searchBarState.next({
    ...currentState,
    show: true,
    isGlobal
  });
};

export const hideSearchBar = () => {
  searchBarState.next({
    overlay: false,
    show: false,
    type: undefined,
    isGlobal: true,
  });
};

export const setSearchBarType = (type) => {
  const currentState = searchBarState.getValue();
  searchBarState.next({
    ...currentState,
    overlay: true,
    type
  });
};
