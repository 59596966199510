function setStorageItem(itemName, item) {
  sessionStorage.setItem(itemName, JSON.stringify(item));
}

function getStorageItem(itemName) {
  return JSON.parse(sessionStorage.getItem(itemName));
}

function removeStorageItem(itemName) {
  sessionStorage.removeItem(itemName);
}

function clearStorage() {
  sessionStorage.clear();
}

export default {
  set: setStorageItem,
  get: getStorageItem,
  remove: removeStorageItem,
  clear: clearStorage
};