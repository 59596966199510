const string = (word = '') => {
  if (!word) return '';
  // Fetch the last letter
  const finalLetter = word.substr(-1);

  if (finalLetter === 's') {
    return word;
  } else if (finalLetter === 'y' && !['a', 'e', 'i', 'o', 'u',].includes(word.substr(-2, 1))) {
    return `${word.slice(0, -1)}ies`;
  }

  return `${word}s`;
};

/**
 * 'item' => 'items'
 *
 * @param {String|Array} word
 * @param {Object} options
 *
 */

const number = (value = 0, word, options) => {
  options = options || {};
  let output: string | string[];
  const includeNumber = options.includeNumber;
  if (typeof word !== 'string' && Array.isArray(word)) {
    output = word.map(w => 
      `${includeNumber ? `${value} ` : ''}${value === 1 ? w : string(w)}`
    );
  } else {
    output = `${includeNumber ? `${value} ` : ''}${value === 1 ? word : string(word)}`;
  }

  return output;
};

export default {
  string,
  number
};