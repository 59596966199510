import { BehaviorSubject } from 'rxjs';
import { httpRequest } from '@/functions/httpRequest';

export const mailState = new BehaviorSubject({
  data: [],
  details: {}
});

export async function getAccountInfo() {
  try {
    const details = await httpRequest('get', 'nylas/account', {}, {
      area: 'ecisync',
      throwErrors: false,
    });
    mailState.next({
      data: [],
      details
    });
  } catch(error) {
    console.error(error);
    mailState.next({
      data: [],
      details: {}
    });
  }
}

export async function getMail(params = {
  limit: '1',
  offset: '0',
  mode: 'compact'
}) {
  const searchParams = new URLSearchParams(params);
  try {
    const resp = await httpRequest(
      'get',
      `messages?${searchParams.toString()}`,
      {},
      { 
        version: '2', 
        area: 'ecisync',
        throwErrors: false,
      }
    );
    const existingState = mailState.getValue();
    mailState.next({
      ...existingState,
      data: resp.data
    });
    return resp.data;
  } catch(error) {
    console.error(error);
  }
}

export async function sendMail(payload) {
  try {
    const resp = await httpRequest(
      'post',
      'messages',
      payload,
      { version: '2', area: 'ecisync', }
    );
    return resp.data;
  } catch(error) {
    console.error(error);
  }
}