export const keyList = [
  'address_line_1',
  'address_line_2',
  'address_line_3',
  'address_line_4',
  'town',
  'county',
  'post_code',
  'country',
];

export const isValidAddress = (addressData) => {
  const address = Array.isArray(addressData) ? addressData[0] : addressData;
  if (!address) return false; 

  const {
    address_line_1,
    address_line_2,
    address_line_3,
    address_line_4,
    town,
    county,
    post_code,
    country,
    latitude,
    longitude
  } = address;

  return (latitude && longitude) || [address_line_1, address_line_2, address_line_3, address_line_4, town, county, post_code, country].some(x => !!x);
};

export const showAddressOnMap = (address) => {
  if (!isValidAddress(address)) return;

  const {
    address_line_1,
    address_line_2,
    address_line_3,
    address_line_4,
    town,
    county,
    post_code,
    country,
    latitude,
    longitude,
  } = address;
  
  // priority 1: lat, lng
  // priority 2: postcode + any additional information provided.
  let query: string | string[] = (latitude && longitude) 
    ? [latitude, longitude] 
    : [post_code, address_line_1, address_line_2, address_line_3, address_line_4, town, county, country];
  query = query.filter(part => !!part).join();
  window.open(`${process.env.VUE_APP_GOOGLE_MAP_URI}/search/?api=1&query=${query}`,'_blank');
};

export function getAddressAsString(addressData = {}, keys = keyList) {
  // Check if the input is an array and take the first element if so
  const address = Array.isArray(addressData) ? addressData[0] : addressData;
  let addressStr = '';
  if (address && typeof address === 'object') {
    let firstStr = true;
    keys.forEach(key => {
      if (address[key]) {
        addressStr += `${firstStr ? '' : ', '}${address[key]}`;
        if (firstStr) firstStr = false;
      }
    });
  }
  return addressStr;
}

export default {
  isValid: isValidAddress,
  showOnMap: showAddressOnMap,
  asString: getAddressAsString,
};