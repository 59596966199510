import { BehaviorSubject } from 'rxjs';
import { httpRequest } from '@/functions/httpRequest';

export const currencyState = new BehaviorSubject({
  name: undefined,
});

export async function getCurrency() {
  try {
    const data = await httpRequest('get', `${process.env.BUREAU_URL}/user`);
    const { tenant_id } = await httpRequest('get', 'tenants/current', {}, {
      version: '2',
      area: 'iam'
    });
    let name = 'GBP';
    data.user.hubs.forEach(hub => {
      if (hub.id === tenant_id) {
        name = hub.currencyCode;
      }
    });

    currencyState.next({ name });
  } catch(error) {
    console.error(error);
  }
}