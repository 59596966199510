import { t } from './translate';
/**
 * Functions to work with date and time
 * @todo: internationalisation for user formatting
 * @author: diakovlev
 */
import { DateTime, Info } from 'luxon';
export const DEFAULT_DATE_LOCALE = 'en-GB';

/**
 * @function formatDate
 * Formats date
 * See formats: https://moment.github.io/luxon/#/formatting
 *
 * @param {date|string} date Date string or object
 * @param {string} [format = DD/MM/YYYY] Output format
 * @param {string} [inputFormat = ISO] Input format
 * @return {string} date formatted @format format
 */
export function formatDate(date, format = 'short', inputFormat?, locale?) {
  const formats = {
    short: DateTime.DATE_SHORT,
    shortwtime: DateTime.DATETIME_SHORT,
    medium: 'DDDD', // 'Do MMMM YYYY',
    long: DateTime.DATE_HUGE, //'dddd Do of MMMM YYYY'
    timeSimple: DateTime.TIME_SIMPLE // 1:30 PM or 13:30 (depending on locale)
  };
  // if no named format found, it means custom format string
  const resultingFormat = formats[format || formats.short] || format;
  let dt = getDateTime(date, inputFormat);
  if (!dt) return '';
  dt = dt.setLocale(locale || DEFAULT_DATE_LOCALE);
  const localeString = resultingFormat
    ? dt.toLocaleString(resultingFormat)
    : dt.toLocaleString();
  return localeString.replace('Invalid DateTime', '');
}

export function getDateTime(value, inputFormat?) {
  if (!value) return undefined;
  if (DateTime.isDateTime(value)) return value;
  if (typeof value === 'string') {
    if (inputFormat) return DateTime.fromFormat(value, inputFormat);
    else return DateTime.fromISO(value);
  }
  else return DateTime.fromJSDate(value);
}

export function formatDateAsISO(date) {
  if (!date) return date;
  return getDateTime(date).toISO();
}

function getDifferenceHoursMins(pastDate, futureDate) {
  const difference = futureDate - pastDate;
  const hours = Math.floor(difference / 60 / 60 / 1000);
  const mins = Math.floor(difference / 60 / 1000);
  return { difference, hours, mins };
}

export function getContractedTime(pastDate, futureDate = new Date()) {
  if (pastDate) {
    const { difference, hours, mins } = getDifferenceHoursMins(pastDate, futureDate);
    const under10Mins = (60 * 1000) * 10;
    if (difference <= under10Mins) {
      return t('Just Now');
    }
    if (hours < 24 && hours >= 1) {
      return hours === 1 ?
        t('1 hour ago') :
        t(
          `${hours} hours ago`,
          'number_hours_ago',
          { interpolations: { number: hours }}
        );
    }
    if (mins < 60 && mins >= 10) {
      return t(
        `${mins} mins ago`,
        'number_mins_ago',
        { interpolations: { number: mins }}
      );
    }
    return getFullTime(pastDate, false);
  }
  return '';
}

export function getFullTime(date, includeTime = true) {
  const day = addZero(date.getDate());
  const month = addZero(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = addZero(date.getHours());
  const mins = addZero(date.getMinutes());

  return includeTime ?
    `${day}/${month}/${year} ${hours}:${mins}` : `${day}/${month}/${year}`;
}

export function addZero(num) {
  return num.toString().length === 1 ? `0${num}` : num;
}

export function getDuration(pastDate, futureDate = new Date()) {
  const { hours, mins } = getDifferenceHoursMins(pastDate, futureDate);
  return {
    hours,
    minutes: mins % 60,
  };
}

export function getDateForApi(date, onlyDate = false) {
  return onlyDate 
    ? getDateTime(date).startOf('second').toISODate() 
    : getDateTime(date).startOf('second').toISO({suppressMilliseconds: true});
}

export function getDateForReportApi(date) {
  if (!date) return null;
  if ( typeof date == 'string' && date.length > 10 && !date.includes('Z')) return date;

  let fullDate = getDateTime(date).toISO({suppressMilliseconds: true});
  const parts = getTimeDifference(fullDate);
  fullDate = `${parts.time}${parts.modifier}${parts.difference.replace(':', '')}`;
  return fullDate;
}

function getTimeDifference(date) {
  let time = '';
  let modifier = '';
  let difference = '';
  if (date) {
    const endLen = date.length - 6;
    const dateEnd = date.substr(endLen);
    time = date.substr(0, endLen);
    difference = dateEnd.substr(1);
    if (dateEnd.startsWith('+')) {
      modifier = '+';
    }
    if (dateEnd.startsWith('-')) {
      modifier = '-';
    }
  }
  return { time, modifier, difference };
}

export function getStartHours(value) {
  if (!value) return '';
  const hrs = value.getHours().toString();
  return hrs.length >= 2 ? hrs : `0${hrs}`;
}

export function getStartMinutes(value) {
  if (!value) return '';
  const mins = value.getMinutes().toString();
  return mins.length >= 2 ? mins : `0${mins}`;
}

export function getDurationHours(value) {
  const { hours = 0 } = (value || {});
  if (hours) {
    return typeof hours === 'number' ? hours : parseInt(hours, 10);
  }
  return 0;
}

export function getDurationMinutes(value) {
  const { minutes = 0 } = (value || {});
  if (minutes) {
    return typeof minutes === 'number' ? minutes : parseInt(minutes, 10);
  }
  return 0;
}

export function isTodayOrFutureDate(date) {
  return date && getDateTime(date).startOf('day') >= getDateTime(new Date()).startOf('day'); 
}

export function getFormattedDateWithOrdinals(date) {
  const dt = date ? new Date(date) : new Date();
  const formattedDate = dt.toLocaleDateString('en-GB', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  const day = formattedDate.split(' ')[1];
  const ordinal = (n) =>  {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n%100;
    return n + (s[(v-20)%10] || s[v] || s[0]);
  };

  return formattedDate.replace(day, ordinal(day));
}

/**
 * Calculate diff between dates as an object {years, months, days, hours, ..}
 * @param {*} dateToCompare 
 * @param {*} baseDate Now by default
 * @param {*} units ['years', 'months', 'days', 'hours']
 * @returns 
 */
export function getDatesDiff(dateToCompare, baseDate: Date | string = new Date(), units = ['years', 'months', 'days', 'hours']) {
  if (!dateToCompare || !baseDate) return {};
  const baseDateTime = getDateTime(baseDate);
  const dateTimeToCompare = getDateTime(dateToCompare);
  return dateTimeToCompare.diff(baseDateTime, units).toObject();
}

export function getMonths(length = 'long', opts = { locale: 'en-gb'}) {
  return Info.months(length, opts);
}

export function getMonthStartAndEndDatesForAPI(monthNumber = DateTime.now().month, year = DateTime.now().year) {
  // Create a DateTime object for the first day of the given month and year
  const monthStart = DateTime.local(year, monthNumber, 1);
  
  // Create a DateTime object for the last day of the given month and year
  const monthEnd = monthStart.endOf('month');
  
  // Return an object containing the start and end dates
  return { 
    start: monthStart, 
    end: monthEnd 
  };
}

export default {
  format: formatDate, //
  getDateTime, //
  formatAsISO: formatDateAsISO, //
  getContractedTime, //
  getFullTime, //
  addZero, //
  getDuration, //
  forApi: getDateForApi, //
  forReportApi: getDateForReportApi, //
  getStartHours, //
  getStartMinutes, //
  getDurationHours, //
  getDurationMinutes, //
  isTodayOrFutureDate,
  formatWithOrdinals: getFormattedDateWithOrdinals, //
  getDiff: getDatesDiff, //
  getMonths, //
  getMonthStartAndEndDatesForAPI, //
};