import { BehaviorSubject } from 'rxjs';
  
export const alertsState = new BehaviorSubject({
  data: [],
});
  
export function setAlerts(items) {
  const state = alertsState.getValue();
  const alerts = state.data.concat(items);
  alertsState.next({ data: alerts });
}
  
export function removeAlertByIndex(index = 0) {
  const state = alertsState.getValue();
  const newData = state.data.slice();
  newData.splice(index, 1);
  alertsState.next({ data: newData });
}
  
export function clearAlerts() {
  alertsState.next({
    data: [],
  });
}