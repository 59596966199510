import { BehaviorSubject } from 'rxjs';
import { Subject } from '@/types/tenantSubjectTypes';
import { httpRequest } from '@/functions/httpRequest';

export const userDetailsState = new BehaviorSubject({
  data: <Subject>{},
  error: false,
  pending: false,
});

export const isLoggedInState = new BehaviorSubject(null);

export async function getUserDetails() {
  try {
    const user = await httpRequest('get', 'subjects/me', {}, { area: 'iam', version: '2', });
    isLoggedInState.next(true);
    return userDetailsState.next({
      data: user,
      error: false,
      pending: false,
    });
  } catch(e) {
    isLoggedInState.next(false);
    console.error('e', e);
  }
}